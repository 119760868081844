// cloudinary configurations
export const CloudinaryCloudName = process.env.CLOUDINARY_CLOUD_NAME;
export const CloudinaryUploadPreset = process.env.CLOUDINARY_UPLOAD_PRESET;
export const CloudinaryAPIKey = process.env.CLOUDINARY_API_KEY;
export const CloudinaryImageUploadUrl = `https://api.cloudinary.com/v1_1/${CloudinaryCloudName}/image/upload`;
export const CloudinaryVideoUploadUrl = `https://api.cloudinary.com/v1_1/${CloudinaryCloudName}/video/upload`;

// DC Contact Details
export const DCContactNumber = '+1 833-471-7100';
export const DCContactNumberHRef = 'tel:+1-833-471-7100';
