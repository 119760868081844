import { SYNC_UP_SESSION } from 'Utils/actionTypes';
import { setUserData } from 'Bundles/Client/common/actions/userActions';
import ApiUtils from 'Utils/apiUtil';
import ReactOnRails from 'react-on-rails';

const initialState = {
  pageNo: 1,
};

export const common = (state = initialState, action) => {
  switch (action.type) {
    case SYNC_UP_SESSION:
      if (!action.payload) {
        const csrfToken = ReactOnRails.authenticityToken();
        setUserData([]);

        ApiUtils.userSignOut(csrfToken)
          .then(() => {
            localStorage.clear();
            window.location = '/users/sign_in';
          })
          .catch(() => {
            localStorage.clear();
            window.location = '/users/sign_in';
          });

        return false;
      }
      return true;

    default:
      return state;
  }
};
