import * as errorTracker from 'Utils/errorTracker';

const APP_REDUX_STATE = 'appReduxState';

/**
 * Method to detect if storage is available in the browser
 * @link: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 */
const storageAvailable = type => {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Storage is not available: ', e.message);
    return false;
  }
};

const loadState = () => {
  if (!storageAvailable('localStorage')) {
    return;
  }
  try {
    const serializedState = localStorage.getItem(APP_REDUX_STATE);
    if (serializedState === null) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return JSON.parse(serializedState);
  } catch (err) {
    errorTracker.captureException(err);
  }
};

const saveState = state => {
  if (!storageAvailable('localStorage')) {
    return;
  }
  const { provider, location, user } = state;
  try {
    const serializedState = JSON.stringify({ provider, location, user });
    localStorage.setItem(APP_REDUX_STATE, serializedState);
  } catch (err) {
    errorTracker.captureException(err);
  }
};

export const PersistStore = {
  loadState,
  saveState,
};
