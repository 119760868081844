import * as env from 'Utils/env';

const shouldUseSentry = env.isProductionLike && !env.isDryRun && !env.isServerRendering;
const Sentry = shouldUseSentry ? require('@sentry/browser') : undefined;

const blacklistUrls = [
  // Ignore Google flakiness
  /\/(gtm|ga|analytics)\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // TODO: remove it when https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/19217918/ is fixed
  /\/maps-api-v3\/api\/js\/.*\/common.js/i,
];

const isMatchingPattern = (value, pattern) => {
  if (Object.prototype.toString.call(pattern) === '[object RegExp]') {
    return pattern.test(value);
  }
  if (typeof pattern === 'string') {
    return value.indexOf(pattern) !== -1;
  }
  return false;
};

const beforeSend = event => {
  /**
   * InboundFilter only verifies first line of stack trace but sometimes the filtered url is located
   * in another line.
   * The following code tests blocklistUrls through all lines of stack trace
   * @link https://github.com/getsentry/sentry-javascript/blob/master/packages/core/src/integrations/inboundfilters.ts#L177
   */
  let frameContainer;
  if (event.stacktrace) {
    frameContainer = event.stacktrace;
  } else if (event.exception && event.exception.values) {
    frameContainer = event.exception.values[0].stacktrace;
  }
  if (frameContainer) {
    const possibleFiles = frameContainer.frames.map(frames => frames.filename).join('|');
    if (blacklistUrls.some(pattern => isMatchingPattern(possibleFiles, pattern))) {
      return null;
    }
  }
  return event;
};

const initBugTracker = () => {
  if (typeof window !== 'undefined' && Sentry && window.SENTRY_DSN) {
    Sentry.init({
      dsn: window.SENTRY_DSN,
      release: window.GIT_SHA,
      environment: window.SENTRY_ENV,
      beforeSend,
      blacklistUrls,
      ignoreErrors: [
        /NS_ERROR_FILE_NO_DEVICE_SPACE/i,
        /DOMException: play\(\) failed because the user didn't interact with the document first./,
      ],
    });
    Sentry.configureScope(scope => scope.setUser(window.SENTRY_USER_CONTEXT));
  }
};

initBugTracker();

export const getApiErrorMsg = error =>
  'API Encountered Bad Response:\n' +
  `error code: ${error.status}\n` +
  `error data: ${JSON.stringify(error.body, null, 2)}\n`;

export const captureMessage = (message, context) => {
  if (Sentry) {
    Sentry.captureMessage(message);
  } else {
    // eslint-disable-next-line no-console
    console.error(
      '[Error Notifier]: In `production` environment tracker will be notified w/ this message:\n',
      message,
      context,
    );
  }
};

export const captureException = (error, context) => {
  if (Sentry) {
    if (context) {
      Sentry.configureScope(scope => {
        if (context.tags) {
          Object.keys(context.tags).forEach(tag => scope.setTag(tag, context.tags[tag]));
        }
        if (context.level) {
          scope.setLevel(context.level);
        }
        if (context.extra) {
          Object.keys(context.extra).forEach(key => scope.setExtra(key, context.extra[key]));
        }
      });
    }
    Sentry.captureException(error);
  } else {
    // eslint-disable-next-line no-console
    console.error(
      '[Error Notifier]: In `production` environment tracker will be notified of this error:',
      error,
      context,
    );
  }
};

export const captureApiError = (nativeError, error) => {
  const isApiError = !!error.body && !!error.status;

  switch (process.env.NODE_ENV) {
    case env.Environment.TEST: {
      if (!isApiError) {
        throw error;
      }
      return;
    }
    case env.Environment.DEV: {
      console.error(error); // eslint-disable-line no-console
      return;
    }

    default: {
      if (isApiError) {
        captureException(nativeError, {
          tags: { 'api-error': error.status },
          extra: {
            responseStatusCode: error.status,
            responseBody: JSON.stringify(error.body, null, 2),
          },
        });
      } else {
        captureException(error);
      }
    }
  }
};
