// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactGa from "react-ga";

var trackingId = (typeof(window) !== "undefined" && window.GA_TID || "");

function init() {
  ReactGa.initialize(trackingId);
}

export {
  trackingId ,
  init ,
}
/* trackingId Not a pure module */
