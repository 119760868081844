import { CART_TOGGLE, SET_CART_QTY } from 'Utils/actionTypes';

const initialState = { isOpen: false, totalCartQuantity: 0 };

export const cart = (state = initialState, action) => {
  switch (action.type) {
    case CART_TOGGLE:
      return { ...state, isOpen: !state.isOpen };
    case SET_CART_QTY:
      return { ...state, totalCartQuantity: action.payload.totalCartQuantity };
    default:
      return state;
  }
};
