import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';

const ClientAppHOC = (WrappedComponent, name, options) => (props, context, domNodeId) => {
  const container = document.getElementById(domNodeId);

  if (options && options.ssr === false) {
    // Client-side rendering
    const root = createRoot(container);
    root.render(<WrappedComponent {...props} />);
    return;
  }

  loadableReady(
    () => {
      // SSR hydrating
      hydrateRoot(container, React.createElement(WrappedComponent, { ...props, context }));
    },
    {
      namespace: name,
    },
  );
};

export default ClientAppHOC;
