import { SET_USER_DATA } from 'Utils/actionTypes';

export const user = (state = [], action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return action.payload;

    default:
      return state;
  }
};
