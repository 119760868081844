import { configureStore } from '@reduxjs/toolkit';
import { RootReducer } from '../rootReducer';
import { PersistStore } from '../PersistStore';

export default (props, _context) => {
  const initialState = PersistStore.loadState() || {};
  const { user } = props;
  if (typeof user === 'string') {
    initialState.user = JSON.parse(user);
  } else {
    initialState.user = user;
  }

  const store = configureStore({
    reducer: RootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  store.subscribe(() => PersistStore.saveState(store.getState()));
  return store;
};
