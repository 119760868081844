import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { common } from './AdminPanel/common/reducers';
import { user } from './Client/common/reducers/userReducer';
import { cart } from './Client/common/reducers/cartReducer';

export const RootReducer = combineReducers({
  common,
  user,
  cart,
  form: formReducer,
});

export const reducers = {
  common,
  user,
  cart,
  form: formReducer,
};
