// --------------  HelloWorld Start -------------- //
export const HELLO_WORLD_NAME_UPDATE = 'HELLO_WORLD_NAME_UPDATE';
// --------------  HelloWorld End -------------- //

// --------------  common Start -------------- //
export const SYNC_UP_SESSION = 'SYNC_UP_SESSION';
// --------------  common End -------------- //

// --------------  User Start -------------- //
export const SET_USER_DATA = 'SET_USER_DATA';
// --------------  User End -------------- //

// --------------  Cart open close client side -------------- //
export const CART_TOGGLE = 'CART_TOGGLE';
export const SET_CART_QTY = 'SET_CART_QTY';
// --------------  Cart open close client side -------------- //
